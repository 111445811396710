<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <div class="col-12">
          <h5>Add Attribute</h5>
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-6">
              <label for="attribute-name">Attribute Name</label>
              <InputText v-model="form.attr_name" id="attribute-name" type="text" />
            </div>
            <div class="field col-12">
              <label for="attribute-desc">Attribute Description</label>
              <Textarea v-model="form.attr_desc" id="attribute-desc" rows="4" />
            </div>
            <div class="field col-12 md:col-12" style="display: flex; align-items: center; gap: 1rem">
              <div class="" style="display: flex; align-items: center; gap: 1rem">
                <label for="weight-desc">Card Type ?</label>
              </div>
              <Dropdown v-model="form.type" :options="card_types" optionLabel="name" placeholder="Select" />
            </div>
            <div class="field col-12 md:col-3" style="display: flex; align-items: center; gap: 1rem">
              <div class="" style="display: flex; align-items: center; gap: 1rem">
                <label for="weight-desc">Use Value input ?</label>
              </div>
              <InputSwitch v-model="form.is_use_value_input" />
            </div>
            <div class="field col-12 md:col-9" style="display: flex; align-items: center; gap: 1rem">
              <div class="" style="display: flex; align-items: center; gap: 1rem">
                <label for="weight-desc">Use multiple Value input ?</label>
              </div>
              <InputSwitch
                v-model="form.is_multiple_input"
                @change="
                  () => {
                    form.is_use_value_input = form.is_multiple_input;
                  }
                "
              />
            </div>
            <!-- break -->
          </div>
          <Button v-if="!$route.query.id" @click="createAttribute" label="Create Attribute" class="mr-2 mb-2" />
        </div>
        <div v-if="$route.query.id" class="">
          <div v-for="(list, index) in attribute_list" :key="index + 5" class="col-12">
            <div class="p-fluid formgrid grid">
              <div class="field col-12" style="display: flex; align-items: center">
                <Button @click="removeList(list.id)" label="" class="p-button-danger mr-2 mb-2" icon=" pi pi-trash" />
                <span style="font-size: 1.2rem">Attribute List {{ `(${index + 1})` }}</span>
              </div>
              <div class="field col-1"></div>
            </div>
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-4">
                <label for="firstname2">Name</label>
                <InputText v-model="list.name" id="firstname2" type="text" />
              </div>
              <div class="field col-12">
                <label for="lastname2">Attribute Description</label>
                <Textarea v-model="list.description" id="address" rows="4" />
              </div>
              <div class="field col-12 md:col-4">
                <label for="address">Price</label>
                <InputNumber v-model="list.price" showButtons mode="decimal"></InputNumber>
              </div>
              <div class="field col-12 md:col-4">
                <label for="city">Discount price (VIP price)</label>
                <InputNumber v-model="list.discount_price" showButtons mode="decimal"></InputNumber>
              </div>
              <div class="field col-12 md:col-4">
                <label for="city">Qty</label>
                <InputNumber v-model="list.qty" showButtons mode="decimal"></InputNumber>
              </div>
              <div class="field col-12">
                <label for="city">Weight (KG)</label>
                <InputNumber v-model="list.weight" showButtons mode="decimal"></InputNumber>
              </div>
              <div class="field col-12">
                <input
                  @change="
                    (e) => {
                      list.file = e.target.files[0];
                      list.preview = URL.createObjectURL(list.file);
                    }
                  "
                  type="file"
                />

                <img class="mt-4" width="200" :src="list.preview" alt="" />

                <!-- start price rulse -->
              </div>
              <div class="col-12">
                <div class="col-3">
                  <Button label="Add Price rules" class="p-button-outlined mr-2 mb-2 mt-5" @click="AddPriceRule(index)" />
                </div>
                <div class="col">
                  <Card class="mb-2" v-for="(data, i) in list.price_rules" :key="i + 102">
                    <template v-slot:title>
                      <div class="flex align-items-center justify-content-between mb-0">
                        <p>Price rules #{{ i + 1 }}</p>
                        <Button icon="pi pi-times" class="p-button-text" @click="RemovePriceRules(index, i)" />
                      </div>
                    </template>

                    <template v-slot:content>
                      <MultiSelect
                        display="chip"
                        class="mt-2 mb-5"
                        v-model="attribute_list[index].price_rules[i].attr_rule"
                        :options="multiselectValues"
                        optionLabel="name"
                        placeholder="Select Countries"
                        :filter="true"
                      >
                        <template #value="slotProps">
                          <template v-if="!slotProps.value || slotProps.value.length === 0">
                            <div class="p-1">Select Attributes</div>
                          </template>
                        </template>
                      </MultiSelect>
                      <div class="formgrid grid">
                        <div class="field col">
                          <label for="name2">price</label>
                          <InputText v-model="attribute_list[index].price_rules[i].price" id="name2" type="text" />
                        </div>
                        <div class="field col">
                          <label for="email2">discount price</label>
                          <InputText v-model="attribute_list[index].price_rules[i].discount_price" id="email2" type="text" />
                        </div>
                      </div>
                    </template>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="" style="display: flex; width: 100%; justify-content: center">
          <Button @click="addList" label="Add List" class="mr-2 mb-2 p-button-secondary" />
          <Button :disabled="!attribute_list.length" @click="showModalSaveAll = true" label="Save All" class="mr-2 mb-2" />
        </div>
      </div>
    </div>
    <Dialog v-model:visible="showModalSaveAll" :style="{ width: '450px' }" header="Submit Attribute Confirm ?" :modal="true">
      <div class="flex align-items-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span>Are you sure submit all attribute list ?</span>
      </div>
      <template #footer>
        <Button @click="showModalSaveAll = !showModalSaveAll" label="No" icon="pi pi-times" class="p-button-text" />
        <Button label="Yes" icon="pi pi-check" class="p-button-text" :loading="isLoading" @click="submitAttrList" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import AttributeApi from "../../api/Attribute";
import BaseURL from "../../api/BaseURL";

export default {
  data() {
    return {
      baseUrl: BaseURL,
      showModalSaveAll: false,
      form: {
        attr_name: "",
        attr_desc: "",
      },
      attribute_list: [],
      files: [],
      loading: false,
      multiselectValue: null,
      multiselectValues: [],
      card_types: [
        { name: 1, value: 1 },
        { name: 2, value: 2 },
        { name: 3, value: 3 },
        { name: 4, value: 4 },
        { name: 5, value: 5 },
        { name: 6, value: 6 },
        { name: 7, value: 7 },
        { name: 8, value: 8 },
        { name: 9, value: 9 },
      ],
    };
  },
  mounted() {
    this.attributeId();
  },

  watch: {
    $route() {
      if (this.$route.query.id !== "") {
        this.addList();
      }
    },
  },

  methods: {
    handleChangeData() {
      alert(this.multiselectValue);
    },
    RemovePriceRules(idx, i) {
      this.attribute_list[idx].price_rules.splice(i, 1);
    },
    AddPriceRule(idx) {
      this.attribute_list[idx].price_rules.push({
        attr_rule: [],
        is_variant_rule: false,
        variant_rule: [],
        price: null,
        discount_price: null,
        attr_list_id: null,
      });
    },
    addList() {
      const id = this.$route.query.id;
      this.attribute_list.push({
        id: this.attribute_list.length + 1,
        price: 0,
        discount_price: 0,
        name: "",
        description: "",
        qty: 0,
        file: null,
        preview: "",
        product_attribute_id: id,
        price_rules: [],
      });
    },
    removeList(id) {
      this.attribute_list = this.attribute_list.filter((list) => list.id !== id);
    },
    async createAttribute() {
      try {
        const response = await AttributeApi.Add({
          ...this.form,
          type: this.form.type.value,
        });

        if (response.data.status === 200) {
          this.$router.push({
            path: "/admin/product-management/attribute/add",
            query: { id: response.data.data._id },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async attributeId() {
      const id = this.$route.query.id;
      const response = await AttributeApi.Details(id);
      let geting = await AttributeApi.GetAllAttribute();
      geting?.data?.data.map((el) => {
        this.multiselectValues.push({
          name: `${el.product_attribute_id.attr_name} - ${el.name}`,
          value: el._id,
        });
      });
      if (response.data.status === 200) {
        this.form = response.data.data;
        response.data.data.attributes_list?.map((list) => {
          this.attribute_list.push({
            ...list,
            preview: `${this.baseUrl.baseURL}/product/${list.desktop_img}`,
          });
        });
        this.attribute_list.push({
          preview: "",
          price: 0,
          discount_price: 0,
          name: "",
          description: "",
          qty: 0,
          weight: 0,
          type: null,
          is_use_value_input: false,
          product_attribute_id: id,
          price_rules: [],
        });
      }
    },
    async submitAttrList() {
      const formData = new FormData();

      let stringifyJSON = JSON.stringify(this.attribute_list);

      formData.append("data", stringifyJSON);

      for (let i = 0; i < this.attribute_list.length; i++) {
        formData.append("file", this.attribute_list[i].file);
      }
      const response = await AttributeApi.addMultipleAttr(formData);

      if (response.data.status === 200) {
        this.$router.push("/admin/product-management/attribute");
      } else {
        this.showModalSaveAll = false;
        this.$toast.add({
          severity: "error",
          summary: "Something went wrong!",
          detail: response.data.message,
          life: 4000,
        });
      }
    },
  },
};
</script>
